import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./transaction.css";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlineSmsFailed } from "react-icons/md";

export default function Transaction() {
  const params = window.location.search;
  const status = params.split("?")[1];
  const urlParams = params.split("?")[2];
  const encodedData = new URLSearchParams(urlParams);
  const toJson = Object.fromEntries(encodedData);
  const navigate = useNavigate();
  useEffect(() => {
    debugger;
    if (status === "status=success") {
      const fetch = async () => {
        const transaction = await axios.post(
          `https://cors-fix-kylo.herokuapp.com/https://eschool.appsosindia.org/views/accounts/php/fee_enquiry_update.php?sc_code=HGSB&
academic_year=11
`,
          {
            fee_id: toJson.fee_id || "",
            status: toJson.status,
            order_id: toJson.order_id,
            tracking_id: toJson.tracking_id,
            transaction_id: toJson.bank_ref_no,
            sc_code: "HGSF",
            academic_year: toJson.academic_year || "11",
          }
        );
        debugger;
      };
      fetch();
      const interval = setInterval(() => {
        navigate("/");
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  return (
    <div>
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto mt-5">
            <div class="payment">
              <div class="payment_header">
                <div class="check">
                  {status === "status=success" ? (
                    <AiOutlineCheckCircle fontSize={50} color="#0076BD" />
                  ) : (
                    <MdOutlineSmsFailed fontSize={50} color="#0076BD" />
                  )}
                </div>
              </div>
              <div class="content">
                <h1>
                  {status === "status=success"
                    ? "Payment Success !"
                    : "Payment Failed"}
                </h1>
                <p>
                  Payment {status === "status=success" ? "Success" : "Failed"} ,
                  Transaction ID : {toJson.order_id}
                </p>
                <a href="/">Go to Home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
