import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import toparrow from "../../image/newImg/toparrow.png";
import SideBar from "../SideBar/SideBar";
import "./Content.css";
import "./Results.css";
import { Col, Row } from "react-bootstrap";
import s1 from "../../image/r10/s1.png";
import s2 from "../../image/r10/s2.png";
import s3 from "../../image/r10/s3.png";
import s4 from "../../image/r10/s4.png";
import s5 from "../../image/r10/s5.png";
import s6 from "../../image/r10/s6.png";
import s7 from "../../image/r10/s7.png";
import s8 from "../../image/r10/s8.png";
import s9 from "../../image/r10/s9.png";
import s10 from "../../image/r10/s10.png";

import s22 from "../../image/r12/s1.png";
import s23 from "../../image/r12/s2.png";
import s24 from "../../image/r12/s3.png";
import s25 from "../../image/r12/s4.png";
import s26 from "../../image/r12/s5.png";
import s27 from "../../image/r12/s6.png";
import s28 from "../../image/r12/s7.png";

const x12th = require("../../excel/varanasi12.xlsx");
const x10th = require("../../excel/varanasi10.xlsx");

function Results(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              className="admissions__image"
              src={toparrow}
              style={{ marginRight: "1rem" }}
            />{" "}
            Results
          </div>
          <Col>
            <div className="row">
              <div className="col-lg-4">
                <div className="result-btn mt-2">
                  <a href={x10th}>
                    <button className="tenth_result">
                      VIEW 10 YEARS RESULT ANALYSIS (X)
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="result-btn mt-2">
                  <a href={x12th}>
                    <button className="tenth_result">
                      VIEW 10 YEARS’ RESULT ANALYSIS (XII)
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <Row className="resultsrow">
              <div style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>
                <b>Toppers: X</b>
              </div>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s1} />
                    <div className="details">
                      <p className="sname">Kinjal</p>
                      <p className="marks">98.8%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s2} />
                    <div className="details">
                      <p className="sname">Nitin Tripathi</p>
                      <p className="marks">98.4%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s3} />
                    <div className="details">
                      <p className="sname">Shreyansh Raghuvanshi</p>
                      <p className="marks">98.4%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s4} />
                    <div className="details">
                      <p className="sname">Anshika Giri</p>
                      <p className="marks">98.2%</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s5} />
                    <div className="details">
                      <p className="sname">Anand Prakash Yadav</p>
                      <p className="marks">97%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s6} />
                    <div className="details">
                      <p className="sname">Janhavi Yadav</p>
                      <p className="marks">97%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s7} />
                    <div className="details">
                      <p className="sname">Aman Singh</p>
                      <p className="marks">96.6%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s8} />
                    <div className="details">
                      <p className="sname">Vaibhav Rai</p>
                      <p className="marks">96.2%</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s9} />
                    <div className="details">
                      <p className="sname">Shivam</p>
                      <p className="marks">95.2%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s10} />
                    <div className="details">
                      <p className="sname">Saumya Yaduvanshi</p>
                      <p className="marks">94.8%</p>
                    </div>
                  </div>
                </Col>
                <Col className="hidden"></Col>
                <Col className="hidden"></Col>
              </Row>
            </Row>
            <Row className="resultsrow">
              <div style={{ fontSize: "1.2rem", marginTop: "2rem" }}>
                <b>Toppers: XII</b>
              </div>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s22} />
                    <div className="details">
                      <p className="sname">Vardhman Sigh Yadav</p>
                      <p className="marks">97.8%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s23} />
                    <div className="details">
                      <p className="sname">Roopal</p>
                      <p className="marks">95.8%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s24} />
                    <div className="details">
                      <p className="sname">Khusi Tripathi</p>
                      <p className="marks">95.8%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s25} />
                    <div className="details">
                      <p className="sname">Vikrant Yadav</p>
                      <p className="marks">95.6%</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s26} />
                    <div className="details">
                      <p className="sname">Kamakshi Pandey</p>
                      <p className="marks">94.4%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s27} />
                    <div className="details">
                      <p className="sname">Aryan Sanidhya Baranwal</p>
                      <p className="marks">93.8%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s28} />
                    <div className="details">
                      <p className="sname">Shresth Yadav</p>
                      <p className="marks">93.4%</p>
                    </div>
                  </div>
                </Col>
                <Col className="hidden"></Col>
              </Row>
              <div className="d-flex justify-content-center align-items-center gap-4"></div>
            </Row>
          </Col>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Results;
