import React from "react";

import "./Contact.css";
function Contact() {
  return (
    <div className="contact_main cursor ">
      <div className="color-white f-1-2 f-500"> Hermann Gmeiner School</div>
      <div className=" mt-4">
        <div className="d-flex  gap-3 mt-3 overflow-wrap-anywhere">
          <div>
            <img
              src={`https://www.pathways.in/assets/img/location_white.svg`}
              className="contact-icon"
            />
          </div>
          <div className="color-white f-1">
            Hermann Gmeiner School, Daniyalpur, Chaubeypur, Varanasi,<br/>
            Uttar Pradesh, India- 221104
          </div>
        </div>
        <div className="d-flex  gap-3 mt-3 overflow-wrap-anywhere">
          <div>
            <img
              src={`https://www.pathways.in/assets/img/email_us_white.svg`}
              className="contact-icon"
            />
          </div>
          <div className="color-white f-1">
          Principal.Varanasi@hermann-gmeiner-edu.org 
          </div>
        </div>
        <div className="d-flex  gap-3 mt-3 overflow-wrap-anywhere">
          <div>
            <img
              src={`	https://www.pathways.in/assets/img/phone_icon_white.svg`}
              className="contact-icon"
            />
          </div>
          <div className="color-white f-1">05422615466</div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
