import Modal from "react-bootstrap/Modal";
import React from "react";

import "./HomeModal.css";
import Slider from "react-slick";
import { HomeModalContent } from ".";
import modalVideo from "../../video/Visit.mp4";

const HomeModal = ({ show, onHide }) => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 7000,
                    autoplay: true,
                    infinite: true,
                    dots: false,
                },
            },
        ],
    };
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            className="home-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backdropFilter: "blur(0px)", zIndex: "9999999" }}
        >
            <Modal.Header closeButton></Modal.Header>
            <video loop autoPlay muted>
                <source src={modalVideo} type="video/mp4" />
            </video>
        </Modal>
    );
};

export default HomeModal;
